<template>
  <div class="exit_email">
    <back-step
      :goList="[{
        url: '/',
        name: `${$t('home.personal.personal.zy')}`
      }, {
        url: '/home/personal',
        name: `${$t('home.personal.personal.grzx')}`
      }]"
      :currentTxt="`${$t('home.personal.exitEmail.xgyx')}`"
    />
    <div class="children_con" v-if="!isExitOk">
      <h5 class="c_con_title">{{$t('home.personal.exitEmail.xgyx')}}</h5>
      <ul class="c_form">
        <li class="f_single">
          <span class="s_label">{{$t('home.personal.exitEmail.yxh')}}</span>
          <div class="iup_box">
            <input type="email" v-model="exitConfig.oldEmail" disabled>
          </div>
        </li>
        <li class="f_single">
          <span class="s_label">{{$t('home.personal.exitEmail.yzm')}}</span>
          <div class="iup_box">
            <input type="number" v-model="exitConfig.oldSmsCaptcha">
            <span
              class="yzm_sp"
              :class="isYzm ? 'yzm_sp_active' : ''"
              @click="getYzm('0')"
            >{{isYzm ? `${$t('home.personal.bandEmail.cxhq')} ${yzmTime}s` : `${$t('home.personal.bandEmail.hqyzm')}`}}</span>
          </div>
        </li>
        <li class="f_single">
          <span class="s_label">{{$t('home.personal.exitEmail.xyxh')}}</span>
          <div class="iup_box">
            <input type="email" v-model="exitConfig.newEmail">
          </div>
        </li>
        <li class="f_single">
          <span class="s_label">{{$t('home.personal.exitEmail.yzm')}}</span>
          <div class="iup_box">
            <input type="number" v-model="exitConfig.newSmsCaptcha">
            <span
              class="yzm_sp"
              :class="isYzm01 ? 'yzm_sp_active' : ''"
              @click="getYzm('1')"
            >{{isYzm01 ? `${$t('home.personal.bandEmail.cxhq')}${yzmTime01}s` : `${$t('home.personal.bandEmail.hqyzm')}`}}</span>
          </div>
        </li>
      </ul>
      <div class="c_foo">
        <div class="cancel_btn flex-x-y-c curp" @click="goBack">{{$t('common.message.qx')}}</div>
        <div class="confirm_btn flex-x-y-c curp" @click="submitForm">{{$t('common.message.qr')}}</div>
      </div>
    </div>
    <exit-success
      v-else
      :isExitOk="isExitOk"
      :successTxt="`${$t('home.personal.bandEmail.yxxgcg')}`"
    />
    <FullLoading v-show="isLoading"/>
  </div>
</template>

<script>
  import BackStep from '../../../cases/cases-detail/childrenPublic/backStep';
  import FullLoading from 'components/full-loading/full-loading';
  import ExitSuccess from './exitSuccess';
  import {modifyEmail, sendEmailCaptcha} from 'common/api/user';
  import {notifyMsg, getUserId} from 'common/js/util';
  import {mapState} from 'vuex';

  export default {
    data() {
      return {
        isLoading: false,
        isExitOk: false,
        isYzm: false,
        yzmTime: 59,
        interTime: null,
        isYzm01: false,
        yzmTime01: 59,
        interTime01: null,
        exitConfig: {
          oldEmail: '',
          newEmail: '',
          newSmsCaptcha: '',
          oldSmsCaptcha: ''
        }
      }
    },
    created() {
      this.exitConfig.oldEmail = sessionStorage.getItem('userEmail') || '';
    },
    methods: {
      getYzm(type) {
        const isYzmName = type === '0' ? 'isYzm' : 'isYzm01';
        const yzmTimeName = type === '0' ? 'yzmTime' : 'yzmTime01';
        const interTimeName = type === '0' ? 'interTime' : 'interTime01';
        if(this[isYzmName]) {
          return false;
        }
        const email = type === '0' ? 'oldEmail' : 'newEmail';
        if(!this.exitConfig[email] || !/^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(this.exitConfig[email])) {
          return notifyMsg(this, 'warning', this.$t('common.message.qtxzqyx'));
        }
        if(this[interTimeName]) {
          clearInterval(this[interTimeName]);
        }
        this.isLoading = true;
        sendEmailCaptcha({
          email: this.exitConfig[email],
          bizType: 'MODIFY_EMAIL'
        }).then(() => {
          this.isLoading = false;
          this[isYzmName] = true;
          this[interTimeName] = setInterval(() => {
            if(this[yzmTimeName] < 1) {
              this[isYzmName] = false;
              this[yzmTimeName] = 59;
              clearInterval(this[interTimeName]);
            }
            this[yzmTimeName]--;
          }, 1000);
        }).catch(() => {
          this.isLoading = false;
        });
      },
      submitForm() {
        const {newEmail, oldEmail, newSmsCaptcha, oldSmsCaptcha} = this.exitConfig;
        const visEmail = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        if(!newEmail || !oldEmail || !newSmsCaptcha || !oldSmsCaptcha) {
          return notifyMsg(this, 'warning', this.$t('common.message.qtxwz'));
        }else if(!visEmail.test(oldEmail)) {
          return notifyMsg(this, 'warning', this.$t('common.message.qtxzqyx'));
        }else if(!visEmail.test(newEmail)) {
          return notifyMsg(this, 'warning', this.$t('common.message.qtxxyx'));
        }
        this.isLoading = true;
        modifyEmail({
          id: getUserId(),
          newEmail,
          oldEmail,
          newSmsCaptcha,
          oldSmsCaptcha
        }).then(() => {
          this.isLoading = false;
          this.isExitOk = true;
        }).catch(() => {
          this.isLoading = false;
        });
      },
      goBack() {
        window.history.go(-1);
      }
    },
    components: {
      BackStep,
      ExitSuccess,
      FullLoading
    },
    computed:{
      ...mapState({
        language: state => state.language,
      }),
    },
    beforeDestroy() {
      if(this.interTime) {
        clearInterval(this.interTime);
      }
      if(this.interTime01) {
        clearInterval(this.interTime01);
      }
    }
  }
</script>

<style scoped lang="scss">
  .exit_email{
    width: 13.44rem;
    margin: 0 auto;
    padding-top: 40px;
    .children_con{
      border-radius: 6px;
      background-color: #fff;
      display: flex;
      align-items: center;
      flex-direction: column;
      overflow: hidden;
      .c_con_title{
        font-size: 0.2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 0.6rem;
        color: $main_theme_color_333;
        border-bottom: 1px solid #E5E5E5;
      }
      .c_form{
        width: 5.44rem;
        margin-top: 0.6rem;
        margin-bottom: 0.6rem;
        .f_single{
          display: flex;
          align-items: center;
          margin-bottom: 0.4rem;
          .s_label{
            width: 0.8rem;
            margin-right: 0.1rem;
            white-space: nowrap;
            display: flex;
            flex-flow: row-reverse;
            font-size: 16px;
            color: $main_theme_color_333;
          }
          .iup_box{
            flex: 1;
            display: flex;
            align-items: center;
            position: relative;
            height: 0.52rem !important;
            input {
              flex: 1;
              border-radius: 4px;
              border: 0.01rem solid #BBBBBB;
              padding: 16px 20px;
              font-size: 14px;
              color: $main_theme_color_333;
            }
            .yzm_sp {
              margin-left: 0.1rem;
              width: 1.2rem;
              height: 0.4rem;
              border-left: 1px solid #E5E5E5;
              color: $main_theme_color;
              text-align: center;
              display: flex;
              align-items: center;
              justify-content: center;
              position: absolute;
              right: 0;
              cursor: pointer;
            }
            .yzm_sp_active {
              color: $main_theme_color_666;
              cursor: default;
            }
          }
        }
      }
    }
    .c_foo{
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 0.35rem;
      .cancel_btn{
        width: 176px;
        height: 42px;
        background: #FFFFFF;
        border-radius: 6px;
        border: 1px solid #BBBBBB;
        font-size: 16px;
        color: $main_theme_color_333;
      }
      .confirm_btn{
        width: 176px;
        height: 42px;
        background: #FCC80E;
        border-radius: 6px;
        font-size: 16px;
        margin-left: 32px;
      }
    }
  }
</style>
