<template>
  <div class="exit_success">
    <h5 class="c_con_title">{{successTit}}</h5>
    <div class="success_box">
      <div class="suc_pic"></div>
      <h5 class="suc_txt">{{successTxt}}</h5>
      <div class="foo_ok" @click="successOk">{{$t('common.message.qr')}}</div>
      <p class="suc_p">{{lang === 'zh_CN' ? time + 's后自动返回' : `Return automatically after ${time}s`}}</p>
    </div>
  </div>
</template>

<script>
  import {mapActions} from 'vuex';
  export default {
    data() {
      return {
        time: 5,
        interTime: null,
        lang: localStorage.getItem('user_lang') || 'zh_CN'
      }
    },
    props: {
      isExitOk: {
        type: Boolean,
        default: false
      },
      successTit: {
        type: String,
        default: () => {
          return '';
        }
      },
      successTxt: {
        type: String,
        default: () => {
          return '';
        }
      }
    },
    mounted() {
      if(this.interTime) {
        clearInterval(this.interTime);
      }
      this.interTime = setInterval(() => {
        if(this.time < 2) {
          clearInterval(this.interTime);
          this.getUserMsg();
          setTimeout(() => {
            const backUrl = sessionStorage.getItem('backUrl') || '/home/personal';
            sessionStorage.removeItem('backUrl');
            this.goPage(backUrl);
          }, 500);
        }
        this.time--;
      }, 1000);
    },
    methods: {
      goBack() {
        window.history.go(-1);
      },
      goPage(url) {
        if(url) {
          this.$router.push(url);
        }
      },
      successOk() {
        this.getUserMsg();
        const backUrl = sessionStorage.getItem('backUrl') || '/home/personal';
        sessionStorage.removeItem('backUrl');
        this.goPage(backUrl);
      },
      ...mapActions([
        'getUserMsg'
      ])
    },
    beforeDestroy() {
      if(this.interTime) {
        clearInterval(this.interTime);
      }
    }
  }
</script>

<style scoped lang="scss">
  .exit_success{
    margin-top: 0.1rem;
    box-shadow: 0 0 0.16rem 0 #ACACAC;
    border-radius: 0.1rem;
    background-color: #fff;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
    .c_con_title{
      background-color: #F4F4F4;
      font-size: 0.24rem;
      text-align: center;
      width: 100%;
      height: 0.8rem;
      line-height: 0.8rem;
    }
    .success_box{
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0.45rem 0 0.41rem;
      font-size: 0.16rem;
      color: #333333;
      .suc_pic{
        width: 0.88rem;
        height: 0.88rem;
        margin-bottom: 0.15rem;
        background-size: 100% 100%;
        background-image: url("/static/images/home/web_modify_ok.png");
      }
      .suc_txt{
        margin-bottom: 0.39rem;
      }
      .foo_ok{
        width: 1.8rem;
        height: 0.4rem;
        line-height: 0.4rem;
        text-align: center;
        border-radius: 0.6rem;
        margin-bottom: 0.16rem;
        cursor: pointer;
      }
      .suc_p{
        color: #999999;
      }
    }
  }
</style>
